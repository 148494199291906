import Wrapper from "./wrapPageElement"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"


var loadingAnim;

export const onClientEntry = (a, pluginOptions = {}) => {
    loadingAnim = document.createElement("div");
    loadingAnim.style.cssText = `
    width: 100%;
    height: 2px;
    background: #AD0000;
    position: fixed;
    bottom: 0px;
    z-index: 1000;
    -webkit-animation: line_animation 2s infinite;
    animation: line_animation 2s infinite;`;

    var animKeyframes = document.createElement("style");
    animKeyframes.textContent = `@keyframes line_animation {
        0%   {left: 0px; width: 0px;}
        50%   {left: 0px; width: 100%;}
        100%   {left: 100%; width: 100%;}
    }`;

    document.body.appendChild(animKeyframes);

    document.body.appendChild(loadingAnim);
};

export const onRouteUpdateDelayed = () => {
    document.body.appendChild(loadingAnim);
}
  
export const onRouteUpdate = () => {
    loadingAnim.remove();
}

export const wrapPageElement = Wrapper;
