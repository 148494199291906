import React, {ReactNode} from "react";
import styled, { ThemeProvider } from "styled-components";
import { themeList, GlobalStyles } from "../../styles";
import {media} from "../../styles/theme";
import { Link } from "gatsby";


class Layout extends React.Component<{uri:string, children:ReactNode, navContent?:ReactNode}>
{
  state = {};



  componentDidMount()
  {
    
  }

  componentWillUnmount()
  {

  }


  render()
  {
    
    return (
       <ThemeProvider theme={themeList[0]}>
            <GlobalStyles />
            <Header>
              <Logo src="/image/kriszti-logo.png" /><HeaderTitle>Kriszti.art</HeaderTitle>
              <HeaderBackground>
                <div className='air air1'></div>
                <div className='air air2'></div>
                <div className='air air3'></div>
                <div className='air air4'></div>
              </HeaderBackground>
            </Header>
            {/*<Nav>
              <NavButton selected={this.props.uri == "/"} to="/">Kezdőlap</NavButton>
              <NavButton selected={this.props.uri?.includes("/recipes")} to="/recipes">Receptek</NavButton>
              <NavButton selected={this.props.uri?.includes("/gallery")} to="/gallery">Galéria</NavButton>
            </Nav>*/}
            
            <Container>
              <ContentRow>
                {this.props.children}
              </ContentRow>
            </Container>
            <BackToTop onClick={() => window.scrollTo(0,0)}>🡅<span className="icon icon-arrow-up"></span></BackToTop>
            <Footer />
          </ThemeProvider>
        )
  }
};

export default Layout;

const Header = styled.header`
  font-family: "DistantStroke";
  text-align: center;
  font-size: 5rem;
  padding-top: 20px;
  padding-bottom: 10px;
  letter-spacing: 0.1rem;
  color: ${(props) => props.theme.colors.main};
  z-index: 1;
  position: relative;
  background: ${(props) => props.theme.colors.white + "ee"};
`;

const HeaderBackground = styled.section`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 99px;
  border-top: 17px solid ${(props) => props.theme.colors.main  + "03"};
  padding-top: 17px;
  transform: scale(1,-1);
  overflow: hidden;
  z-index: -1;
  background: ${(props) => props.theme.colors.main + "22"};
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: 1s ease-out 0s 1 appear;

  .air{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: url("/image/wave.png");
    background-size: 1000px 100px
  }
  .air.air1{
    animation: wave 30s linear infinite;
    z-index: 1000;
    opacity: 1;
    animation-delay: 0s;
    bottom: 0;
  }
  .air.air2{
    animation: wave2 15s linear infinite;
    z-index: 999;
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 10px;
  }
  .air.air3{
    animation: wave 300s linear infinite;
    z-index: 998;
    opacity: 0.2;
    animation-delay: -20s;
    bottom: 15px;
  }
  .air.air4{
    animation: wave2 35s linear infinite;
    z-index: 997;
    opacity: 0.7;
    animation-delay: -35s;
    bottom: 20px;
  }
  @keyframes wave{
    0%{
      background-position-x: 0px; 
    }
    100%{
      background-position-x: 1000px; 
    }
  }
  @keyframes wave2{
    0%{
      background-position-x: 0px; 
    }
    100%{
      background-position-x: -1000px; 
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  z-index: 1;
  padding: 10px;
  border-top: ${(props) => `5px solid ${props.theme.colors.lightGray}`};
  position: relative;
  background: ${(props) => props.theme.colors.white + "ee"};
  box-shadow: 30px 16px 30px rgba(0, 0, 0, 0.03);
`;

const NavButton = styled(Link)<{selected?:boolean}>`
  font-size: 1.1rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  padding: 10px 15px;
  margin: 0 5px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.dark};
  font-family: "Roboto Condensed"; 
  border-bottom: ${(props) => props.selected ? "2px solid " + props.theme.colors.mainSlave  : "none"};
  ${media.mobile`
    font-size: 0.75rem;
    padding: 10px 6px;
  `}
`

const Logo = styled.img`
  height: 100px;
  margin: auto;
  display: block;
`



const HeaderTitle = styled.div`
  padding-right: 17px;
`;

const Container = styled.main`
  margin: 0 20px;
  ${media.mobile`
    margin: 0 10px;
  `}
`;

const ContentRow = styled.div`
`;

const StyledFooter = styled.footer`
  margin-top: 3rem;
  border-width: 0;
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  padding: 2rem;
  color: ${props => props.theme.colors.gray};
  text-align: center;
  span {
    font-family: "DistantStroke";
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    padding-left: 10px;
  }
  box-shadow: 30px -10px 30px rgba(0, 0, 0, 0.03);
`;

const BackToTop = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 30px;
  padding-left: 7px;
  padding-top: -20px;
  box-sizing: border-box;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 10px ${props => props.theme.colors.black + "22"};
  z-index: 2;
  cursor: pointer;
  color: ${props => props.theme.colors.main};
`;


const Footer = () => {
  return (
    <StyledFooter>{`© 2023-${new Date().getFullYear()}`} <span>kriszti.art</span>
    </StyledFooter>
  );
};

export { Footer };
