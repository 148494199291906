import { mediaQuery } from 'styled-media-queries';
import { mulberry32 } from '../utils/seed';

// Totally optional: define some re-usable breakpoint numbers.
export const breakpoint = {
  widedesktop: 1500,
  desktop: 1100,
  tablet:  500,
  mobile:  0,
};
 
export const media = {
  mobile: mediaQuery`(max-width: ${breakpoint.desktop}px)`,
  tabletMin: mediaQuery`(min-width: ${breakpoint.tablet}px)`,
  tablet: mediaQuery`(min-width: ${breakpoint.tablet}px) and (max-width: ${breakpoint.desktop}px)`,
  desktop: mediaQuery`(min-width: ${breakpoint.desktop}px)`,
  widedesktop: mediaQuery`(min-width: ${breakpoint.widedesktop}px)`,
  print:     mediaQuery`print`,
};

export type Theme = {
  colors: {
    main: string;
    mainHover: string;
    mainSlave: string;
    black: string;
    white: string;
    whiteTrans: string;
    background: string;
    bright: string;
    lightGray: string;
    gray: string;
    darkGray: string;
    dark: string;
    modalBackground: string;
    highlight: string;
  };
}

const hslToHex = (h:number, s:number, l:number) => {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = (n:number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `${f(0)}${f(8)}${f(4)}`;
}

const randomColor = () => {
  const seed = mulberry32(new Date().setHours(0,0,0,0));
  const randomInt = (min:number, max:number) => {
    return Math.floor(seed() * (max - min + 1)) + min;
  };

  var h = randomInt(0, 360);
  var s = randomInt(20, 98);
  var l = randomInt(30, 80);
  //return `hsl(${h},${s}%,${l}%)`;
  return hslToHex(h, s, l);
};

//const randomMainColor = Math.floor(mulberry32(new Date().setHours(0,0,0,0))()*16777215).toString(16);
const randomMainColor = randomColor();

const lightTheme:Theme = {
  colors: {
    main: "#" + randomMainColor,
    mainHover: "#" + randomMainColor + "c5",
    mainSlave: "#" + randomMainColor + "aa",
    black: "#000000",
    white: "#ffffff",
    whiteTrans: "#ffffffbd",
    background: "white",
    bright: "#fefefe",
    lightGray: "#eee",
    gray: "#ccc",
    darkGray: "#999",
    dark: "#434343",
    modalBackground: "#ffffff88",
    highlight: "#d9f4ffc5"
  }
};

export const themeList = [
  lightTheme
];


export default lightTheme;
